import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class BoxHeader extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: () => false
  })
  gridHeader;

  @Prop({
    type: String,
    required: false,
    default: () => ''
  })
  title;

  @Prop({
    type: Boolean,
    required: false,
    default: () => false
  })
  hasFilter;

  @Watch('hasFilter')
  changeHasFilter(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.hasFilter = newVal;
    }
  }

  get titleName() {
    return `${this.$t('common.all')} ${this.title}`;
  }
}
