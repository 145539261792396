import { Component, Vue, Watch } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { AgGridVue } from 'ag-grid-vue';
import Box from '@/components/ui/box/box.vue';
import BoxHeader from '@/components/ui/box/box-header/boxHeader.vue';
import { AgGridHelper } from '@/helpers/ag-grid/ag-grid.helper';
import { Action, Getter } from 'vuex-class';
import { ListResponseType } from '@/types/response/list-response.type';
import { ICallbackAdditionalParams, ICallbackGeneralParams } from '@/helpers/ag-grid/ag-grid-datasource';
import { ColDef, ColGroupDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { ActivityType } from '@/types/api/info/activity.type';
import { InfoActivityRequest } from '@/types/request/info-request.types';
import ShowContent from '@/components/ui/modals/showContent.vue';
import { MODAL_CONFIG } from '@/helpers/app.helper';

const title = 'activity.title';

@Component({
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    AgGridVue,
    Box,
    BoxHeader,
  },
})
export default class Activities extends Vue {
  title = title;
  agGrid: AgGridHelper | null = null;

  @Action('getActivities', { namespace: 'info' })
  getActivities!: (params: InfoActivityRequest) => Promise<ListResponseType<ActivityType>>;

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  @Watch('currentLang')
  resetGridHeader(newValue: string, oldValue: string) {
    if (this.agGrid && newValue !== oldValue) {
      this.agGrid.getGridApi()!.refreshHeader();
    }
  }

  beforeMount() {
    this.agGrid = new AgGridHelper(this.initGridOptions())
      .enableAutoSizeColumns(false)
      .enableInfinity(this.gridChangeCallback)
      .build();
  }

  gridChangeCallback(general: ICallbackGeneralParams, additional: ICallbackAdditionalParams) {
    additional.api.showLoadingOverlay();

    return this.getActivities({
      take: general.limit,
      skip: general.offset,
    })
      .then(response => {
        additional.api.hideOverlay();
        return {
          result: response.items,
          count: response.paging.totalCount
        }
      });
  }

  initGridOptions(): GridOptions {
    return {
      columnDefs: this.initColumnDef(),
      enableSorting: false,
      rowHeight: 50,
    }
  }

  initColumnDef(): (ColDef | ColGroupDef)[] {
    return [
      {
        headerValueGetter: () => this.$t('activity.name'),
        field: 'name',
        cellRenderer: 'imageLinkCellRenderer',
        cellRendererParams: {
          imageUrl: (params: ICellRendererParams) => params.data && params.data.image,
          text: (params: ICellRendererParams) => params.data && params.data.name,
          url: (params: ICellRendererParams) => params.data ? `/activities/update/${params.data.id}` : '#',
        }
      },
      {
        headerValueGetter: () => this.$t('activity.locales'),
        field: 'locales',
        cellRenderer: 'localesCellRenderer',
        cellRendererParams: {
          locales: (params: ICellRendererParams) => (params.data && params.data.locales) || [],
        }
      },
      {
        headerValueGetter: () => this.$t('activity.subActivities'),
        field: 'subActivities',
        cellRenderer: 'badgesCellRenderer',
        cellRendererParams: {
          items: (params: ICellRendererParams) => params.data && params.data.subActivities.map(item => ({
            label: item.name,
            active: true
          })),
          displayColumn: false
        },
      },
    ];
  }

  showContent(title: string, content: string) {
    this.$modal.show(ShowContent, { content, title, }, MODAL_CONFIG);
  }
}
